import React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

export const RandomClamped = (num, min, max) => {
  return Math.min(Math.max(num, min), max);
};

const Canvas = styled(motion.svg)`
  width: 100%;
  height: 66vh;
  overflow: visible;
  position: absolute;
  top: 0;
  z-index: -1;
  pointer-events: none;
`;

const _NUM_SHAPES = 15;
const _SIZE_SHAPE = 66;
const SheetPlanner = true;

const HeroBackdrop = () => {
  return (
    <Canvas viewBox={"0 0 100 100"} preserveAspectRatio="xMinYMin slice">
      {[...Array(_NUM_SHAPES)].map((nil, i) => (
        <motion.g
          key={'backdropshape_' + i}
          fillOpacity={RandomClamped(Math.random(), 0, 0.33)}
          transform={`
                scale(${Math.random() * 1.5})
                translate(50 -${_SIZE_SHAPE / 2})
                translate(-${Math.random() * 80} ${-(_SIZE_SHAPE / 2) + Math.random() * 30})            
                rotate(45 ${_SIZE_SHAPE / 2} ${_SIZE_SHAPE / 2})
            `}>
          {SheetPlanner ? (
            <motion.rect
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ opacity: 0 }}
              width={_SIZE_SHAPE}
              height={_SIZE_SHAPE}
              fill={`hsl(22, 100%, ${RandomClamped(Math.random()*100, 50, 100)}%)`}
            />
          ) : (
            <motion.path
              initial={{ scale: 0 }}
              animate={{ scale: 0.33 }}
              exit={{ opacity: 0 }}
              d="M14.318,15.314c0.44,0.299 1.023,0.274 1.436,-0.061c3.398,-2.755 18.092,-14.673 18.092,-14.673c0.89,-0.722 2.148,-0.775 3.096,-0.131c4.006,2.721 14.573,9.897 20.278,13.772c0.889,0.603 1.439,1.592 1.485,2.665c0.045,1.073 -0.42,2.105 -1.254,2.782c-6.467,5.245 -20.013,16.231 -25.573,20.74c-1.461,1.185 -3.527,1.273 -5.083,0.216l-26.259,-17.833c-0.32,-0.218 -0.518,-0.574 -0.535,-0.961c-0.016,-0.387 0.151,-0.759 0.452,-1.003c2.103,-1.706 6.281,-5.094 8.519,-6.909c0.89,-0.722 2.148,-0.776 3.096,-0.132c0,0 1.365,0.927 2.25,1.528Z"
              fill={'#317cb8'}
            />
          )}
        </motion.g>
      ))}
    </Canvas>
  )
}

export default HeroBackdrop