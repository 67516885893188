import React from "react";
import styled from "styled-components"
import { motion } from "framer-motion"
import SPLogo from '../images/SheetPlanner_logo.svg'
// import CFM from '../images/clientfoldermaker_logo.svg'
import { RandomClamped } from "./HeroBackdrop"
import { Facebook, Twitter, Mail } from 'react-feather'
import {Link} from "gatsby"
import DirectMailForm from "./DirectMailForm"

const FooterContainer = styled('footer')`
 height: 300px;
 display: flex;
 flex-direction: column;
 @media(max-width: 768px){
  height: 600px;
 }
`;

const SignupRow = styled('div')`
  height: 80px;
  background: hsla(0, 0%, 0%, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: clamp(36px, 15vw, 250px);
  padding-right: clamp(36px, 15vw, 250px);
  @media(max-width: 768px){
    flex-direction: column;
    height: 140px;
    padding-left: 24px;
    padding-right: 24px;
    &>*{
      width: max-content;
    }
  } 
  & form {
    margin-left: 24px;
    position: relative;
    label{
      opacity: 0.6;
      margin-right: 12px;
      position:relative;
      top: 3px;
    }
    & input[type='email'], input[type='text']{      
      height: 30px;
      background-color: #ffffff;
      border-radius: 3px;
      border: 1px solid var(--sp-orange);
      margin-right: 36px;
      padding-left: 6px;
    }
    & input[type='email']{
      width: 240px;
      box-shadow: inset 0 4px 2px 0 rgba(255, 103, 15, 0.2), inset -36px 0px 0px 0px var(--sp-orange);
    }
    & button {
      appearance: none;
      background: none;
      border: none;
      outline: none;
      position: absolute;
      right: 42px;
      top: 3px;
    }
  }
`
const Colofon = styled('div')`
  flex: 1;
  background: var(--sp-orange);
  position:relative;
`

const Canvas = styled('svg')`
  position:absolute;
  width: 100%;
  height: 220px;
  z-index: 0;
`

const ColofonDataContainer = styled('div')`
  width: 100%;
  height: 100%;
  position:absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  @media(max-width: 768px){
    position: static;
    padding-top: 48px;
    padding-bottom: 48px;
  }
  & > img {
    align-self: center;  
    height: 140px;
    position: absolute;
    left: clamp(36px, 15vw, 250px);
    transform: translateX(-42%);
    @media(max-width: 768px){
      display: none;
    }
  }
  & > div{
    width: clamp(600px, 40%, 1000px);
    display: flex;
    flex-direction: row;
    padding-top: 48px;
    padding-right: 48px;
    height: 100%;
    justify-content: space-between;
    @media(max-width: 768px){
      flex-direction: column;
      width: 100%;
      padding-top: unset;
    }
    & > div {
    margin-right: 24px;
    margin-left: 24px;
      a {
        text-decoration: none;
        color: var(--sp-darkgray);
        pointer-events: auto;
      }
      h6 {
        color: var(--sp-darkgray);
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 12px;
      }
      ul li, address > span {
        color: var(--sp-darkgray);
        list-style-type: none;
        font-weight: 400;
        line-height: 1.6;
        font-style: normal;
        pointer-events: auto;
      }
      svg {
        stroke: var(--sp-darkgray);
        height: 16px;
        position:relative;
        top: 3px;
        right: -6px;
        float: right;
      }
      & figure {
        display: flex;
        flex-direction: row;
        align-items: center;
        figcaption{
          font-weight: 700;
          color: var(--sp-darkgray);
        }
        img {
          height: 32px;
          margin-right: 6px;
        }
      }
      
    }
  }
`

const _NUM_SHAPES = 15;
const _SIZE_SHAPE = 20;

const Footer = () => {
  return (
    <FooterContainer>

      <SignupRow>
        <p><strong>Stay in the loop,</strong> sign up for the SheetPlanner newsletter.</p>
        {/*<form>*/}
        {/*  <label htmlFor={'newsletteraddress'}>Email address:</label>*/}
        {/*  <input id={'newsletteraddress'} type={'email'}/>*/}
        {/*  <button aria-label={'submitMailButton'} onClick={e=>e.preventDefault()}><CornerDownLeft color={'#fff'}/></button>*/}
        {/*</form>*/}

        <DirectMailForm />

      </SignupRow>

      <Colofon>
        <Canvas viewBox={"0 0 100 100"} preserveAspectRatio="xMinYMin slice">
          {[...Array(_NUM_SHAPES)].map((nil, i) => (
            <motion.g
              key={'backdropshape_' + i}
              fillOpacity={RandomClamped(Math.random(), 0, 0.1)}
              transform={`
                scale(${Math.random() * 1.5})
                translate(50 -${_SIZE_SHAPE / 2})
                translate(-${Math.random() * 80} ${-(_SIZE_SHAPE / 2) + Math.random() * 30})            
                rotate(45 ${_SIZE_SHAPE / 2} ${_SIZE_SHAPE / 2})
            `}>

                <motion.rect
                  whileHover={{scale: 1.1}}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ opacity: 0 }}
                  width={_SIZE_SHAPE}
                  height={_SIZE_SHAPE}
                  fill={`hsl(22, 100%, ${RandomClamped(Math.random()*100, 90, 100)}%)`}
                />
            </motion.g>
          ))}
        </Canvas>
        <ColofonDataContainer>
          <img src={SPLogo} alt={'SheetPlanner logo'} />
          <div>
            <div>
              <h6>SheetPlanner</h6>
              <ul>
                <li><Link to={'/releasenotes'}>Release Notes</Link></li>
                <li><Link to={'/help'}>Help</Link></li>
                <li><Link to={'/privacy'}>Privacy</Link></li>
                {/*<li>Blog</li>*/}
              </ul>
            </div>
            <div>
              <h6>Social</h6>
              <ul>
                <li><a href={"https://facebook.com/sheetplanner"} title={"SheetPlanner on Facebook"}>Facebook <Facebook alt={'Facebook icon'}/></a> </li>
                <li><a href={"https://twitter.com/sheetplanner"} title={"SheetPlanner on Twitter"}>Twitter <Twitter alt={'Twitter icon'}/></a> </li>
                <li><a href={"mailto:support@sheetplanner.com"} title={"Mail SheetPlanner"}>Mail <Mail alt={'Mail icon'}/></a> </li>
              </ul>
            </div>
            <div>
              <h6>Company</h6>
              <address>
                <span>SheetPlanner Software, LLC</span><br />
                <span>Alexandria VA, USA</span>
              </address>
              {/*<a href={'https://clientfoldermaker.com'} title={"Client Folder Maker"}>*/}
              {/*  <figure>*/}
              {/*    <img src={CFM} alt={"Client Folder Maker, our other productivity enhancer"}/>*/}
              {/*    <figcaption>Client Folder Maker</figcaption>*/}
              {/*  </figure>*/}
              {/*</a>*/}
            </div>
          </div>
        </ColofonDataContainer>
      </Colofon>
    </FooterContainer>
  )
}

export default Footer