import React, { useState } from "react"
import {CornerDownLeft} from "react-feather"

const DirectMailForm = () => {
  // const [FirstName, setFirstName] = useState('')
  // const [LastName, setLastName] = useState('')
  const [SubscriberEmail, setSubscriberEmail] = useState('')
  return (
    <>
      {/*eslint-disable jsx-a11y/control-has-associated-label */}
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.3/jquery.min.js" />
      <script src="https://www.dm-mailinglist.com/subscribe_forms/localized.js" charSet="UTF-8" />
      <script src="https://www.dm-mailinglist.com/subscribe_forms/subscribe_embed.js" charSet="UTF-8" />

      <form method="post" action="https://www.dm-mailinglist.com/subscribe" data-directmail-use-ajax="1" data-form-id="4f20ae5e" className="directmail-subscribe-form" acceptCharset="UTF-8">
        <input type="hidden" name="form_id" value="4f20ae5e" />
        <input type="email" name="email" style={{display: "none"}}/>
        <div className="directmail-main-table-container">

          {/*<label className="directmail-form-label directmail-default-no-label" htmlFor="directmail-4f20ae5e-first_name">First Name:</label>*/}
          {/*<input type="text" id="directmail-4f20ae5e-first_name" name="first_name" value={FirstName} onChange={(e)=>{setFirstName(e.target.value)}} placeholder="" className=""/>*/}

          {/*<label className="directmail-form-label directmail-default-no-label" htmlFor="directmail-4f20ae5e-last_name">Last Name:</label>*/}
          {/*<input type="text" id="directmail-4f20ae5e-last_name" name="last_name" value={LastName} onChange={(e)=>{setLastName(e.target.value)}} placeholder="" className="" />*/}

          <div style={{position: "relative", width: 'max-content', display: 'inline-block'}}>
            <label className="directmail-form-label directmail-default-no-label" htmlFor="directmail-4f20ae5e-subscriber_email">Email:</label>
            <input type="email" id="directmail-4f20ae5e-subscriber_email" name="subscriber_email" value={SubscriberEmail} onChange={(e)=>{setSubscriberEmail(e.target.value)}} placeholder="" className="directmail-required-field" required />

            <button aria-label={'submitMailButton'} ><CornerDownLeft color={'#fff'}/></button>
          </div>
        </div>
      </form>
    </>
  )
}


export default DirectMailForm;