/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import "../styles.css"
import Header from "./header"
import Footer from "./Footer"
import HeroBackdrop from "./HeroBackdrop"
import ReactGA from "react-ga"

import CookieConsent from "react-cookie-consent";
const Layout = ({ children, navForced, noHeaderFooter = false }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  //--------------------------------------------------------------------------------------------------------------------

  const [InApp, setInApp] = useState(false)


  useEffect(()=>{
    if(window.location.pathname === '/contact/')
      console.log(window.location)
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("inapp")) {
      setInApp(urlParams.get("inapp") !== "false");
    }
  },[InApp])

  //--------------------------------------------------------------------------------------------------------------------


  return (
    <main >
      {!InApp &&
        <>
          <HeroBackdrop />
          {!noHeaderFooter && <Header siteTitle={data.site.siteMetadata.title} navForced={navForced}/> }
          </>
      }
      {!noHeaderFooter && <CookieConsent
        location="bottom"
        buttonText="I accept"
        cookieName="sheetplanner-gdpr-google-analytics"
        style={{ padding: 2,  background: "#fff", borderTop: '1px solid var(--sp-orange)', fontSize: "12px", }}
        buttonStyle={{ background: 'var(--sp-orange)', color: "#fff", fontSize: "12px", borderRadius: 3 }}
        declineButtonStyle={{ background: '#fff', color: "var(--sp-gray)", fontSize: "12px", borderRadius: 3 }}
        expires={150}
        enableDeclineButton
        onAccept={()=>{
            ReactGA.initialize('UA-111760139-1');
            ReactGA.pageview(window.location.pathname + window.location.search);
        }}
      >
        SheetPlanner LLC is interested in its visitors. Therefore, we use cookies for analytical purposes.<br />
        Please read our <Link to={'/privacy'}>Privacy policy</Link> to learn how we value your data.
      </CookieConsent>
      }
      {children}

      {/*-------------------------------------------------------------------*/}

      {(!InApp && !noHeaderFooter)  && <Footer /> }
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
