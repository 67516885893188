import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SPLogo from '../images/SheetPlanner_logo.svg'
import {Menu} from "react-feather"
import { motion } from 'framer-motion'
import styled, { css } from "styled-components"

const NavBar = styled(motion.div)`
  height: 100px;
  width: 100%;
  background: hsla(0,0%,100%,0.9);
  position: fixed;
  top: 0;
  z-index: 10;
  svg{
    display: none;
  }
  & menu {
    position: absolute;
    top: 36px;
    right: 0;
    height: 100%;
    pointer-events: auto;
    ul {
    height: 100%;    
    display: flex;
    flex-direction: row;
     padding-right: 24px;
      li {
        margin-right: 24px;
        display: block;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 1.2;
        font-weight: 400;
        & a {
          text-decoration: none;
          border-bottom: 0 solid var(--sp-orange);
          transition: border-bottom-width 0.1s ease-out;
          pointer-events: auto;
          &:hover {
            border-bottom-width: 3px;
          }
        }
      }
    }
    
  }
    @media(max-width: 768px){
      svg{
        display: block;
      }
      menu {
      pointer-events: auto;
      position: absolute;
      top:0;
      right: 0;
      width: 70px;
      height: 70px;
      overflow: hidden;
      background: linear-gradient(180deg, var(--sp-orange), darkorange );
      transition: all 0.3s cubic-bezier(.41,1.69,.41,1.01);
      ul {
        padding-top: 60px;
        padding-right: 0;
        flex-direction: column;
        li, a{
          font-size: 6vw;
          text-align: center;
          color: #fff;
          padding-top: 24px;
          padding-bottom: 24px;
          margin-right: 0;
        }
      }
      ${props => props.opened && css`
        height: 100vh;
        width: 100%;
  
      `
      }
    }
`
const Logo = styled(motion.img)` 
  &.hero{
    width: 100%;
    margin: 0 auto;
  }
  @media(max-width: 768px){
    position: relative;
    &.hero{
      top: 0;
    }
  }
`

const Header = ({navForced}) => {
  const [ScrolledPastThreshold, setScrolledPastThreshold] = useState(false)
  const [Opened, setOpened] = useState(false)

  useEffect(()=>{
    window.addEventListener('scroll', () => setScrolledPastThreshold(document.querySelector('html').scrollTop > window.innerHeight/8))
    window.addEventListener('resize', () => setOpened(window.innerWidth > 768))

    setScrolledPastThreshold(document.querySelector('html').scrollTop > window.innerHeight/8)
    setOpened(window.innerWidth>768)
  }, [])

  const variants = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
        // type: "spring",
        // velocity: 150
      } },
    hidden: { opacity: 0 },
  }
  const item = {
    visible: { backgroundColor: 'hsla(0,0%,100%,0)', opacity: 1, y: 0 },
    hidden: { backgroundColor: 'hsla(0,0%,100%,0.5)', opacity: 0, y: 30 },
  }

  return (
    <NavBar opened={Opened} animate={{ height: ScrolledPastThreshold || navForced ? 100 : 0, left: 0 }} onClick={()=>{if(window.innerWidth<768) setOpened(!Opened)}}>
      <Menu style={{position: 'absolute', top: 24, right: 24, zIndex:2}} color={'#fff'}/>
      <Logo alt={"SheetPlanner logo"} className={ScrolledPastThreshold || navForced ? '' : 'hero'} src={SPLogo} animate={{ height: ScrolledPastThreshold || navForced ? 100 : '45vh', left: 0 }} />
      <menu>
        {Opened &&
        <motion.ul variants={variants} initial="hidden" animate="visible">
          <motion.li variants={item}><Link to={'/'}>Home</Link></motion.li>
          <motion.li variants={item}><Link to={'/#features'}>Features</Link></motion.li>
          <motion.li variants={item}><Link to={'/#pricing'}>Pricing</Link></motion.li>
          <motion.li variants={item}><Link to={'/tutorials/'}>Tutorials</Link></motion.li>
          <motion.li variants={item}><Link to={'/help/'}>Help</Link></motion.li>
          <motion.li variants={item}><Link to={'/releasenotes/'}>Release notes</Link></motion.li>
          <motion.li variants={item}><Link to={'/privacy/'}>Privacy</Link></motion.li>
        </motion.ul>
        }
      </menu>
    </NavBar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
